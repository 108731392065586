<template>
    <div class="detail_box">
        <div class="basic_box">
            <Basic ref="basic" @reportGet="reportGet"></Basic>
        </div>
        <div class="report_box">
            <Report ref="report" @reportGet="reportGet"></Report>
        </div>
        <div class="bussiness_box">
            <Bussiness ref="bussiness" @reportGet="reportGet"></Bussiness>
        </div>
        <div class="other_box">
            <div class="tab_box">
                <span
                    :class="active == 1 ? 'active' : ''"
                    @click="changeTab(1)"
                    v-if="isShow"
                    >订单信息</span
                >
                <span :class="active == 2 ? 'active' : ''" @click="changeTab(2)"
                    >申请与处理记录</span
                >
                <span :class="active == 3 ? 'active' : ''" @click="changeTab(3)"
                    >内容操作日志</span
                >
            </div>
            <div style="flex: 1">
                <Order
                    ref="order"
                    v-show="active == 1 && isShow"
                    @reportGet="reportGet"
                ></Order>
                <Record
                    ref="record"
                    v-show="active == 2"
                    @reportAuditHistory="reportAuditHistory"
                ></Record>
                <History
                    ref="history"
                    v-show="active == 3"
                    @reportHistory="reportHistory"
                ></History>
            </div>
        </div>
    </div>
</template>

<script>
import {
    reportGet,
    reportHistory,
    reportAuditHistory,
} from '@/api/report/report.js';
import { orderPlanList } from '@/api/order/order.js';
import Basic from './components/basic.vue';
import Report from './components/report.vue';
import Bussiness from './components/bussiness.vue';
import Order from './components/order.vue';
import Record from './components/record.vue';
import History from './components/history.vue';
export default {
    name: '',
    props: {},
    data() {
        return {
            active: 1,
            oneReport: {},
            planList: [],
            isShow: false,
        };
    },
    components: {
        Basic,
        Report,
        Bussiness,
        Order,
        Record,
        History,
    },
    created() {},
    mounted() {
        this.reportGet();
    },
    methods: {
        // retrunShow() {
        //     if (
        //         this.oneReport.trialStatus != 1 &&
        //         this.oneReport.trialStatus != 7 &&
        //         this.oneReport.trialStatus != 8 &&
        //         this.oneReport.trialStatus != 9 &&
        //         this.planList.length == 0
        //     ) {
        //         this.active = 2;
        //         this.changeTab(2);
        //         return false;
        //     } else {
        //         return true;
        //     }
        // },
        changeTab(i) {
            this.active = i;
            if (i == 1) {
                this.orderPlanList();
            }
            if (i == 2) {
                this.reportAuditHistory();
            }
            if (i == 3) {
                this.reportHistory();
            }
        },
        // 获取单条数据
        reportGet() {
            let data = {
                param: {
                    id: this.$route.query.id,
                },
            };
            reportGet(data).then((res) => {
                if (res.code == 200) {
                    this.oneReport = res.data;
                    this.$refs.basic.getData(res.data);
                    this.$refs.report.getData(res.data);
                    this.$refs.bussiness.getData(res.data);
                    if (this.active == 1) {
                        this.orderPlanList();
                    }
                    if (this.active == 2) {
                        this.reportAuditHistory();
                    }
                    if (this.active == 2) {
                        this.reportHistory();
                    }
                }
            });
        },
        orderPlanList() {
            if (this.oneReport.nicheNo) {
                let data = {
                    param: {
                        nicheNo: this.oneReport.nicheNo,
                    },
                    pageSize: 0,
                    pageNum: 0,
                };
                orderPlanList(data).then((res) => {
                    if (res.code == 200) {
                        this.planList = res.data.list;
                        if (
                            (this.oneReport.trialStatus == 1 ||
                                this.oneReport.trialStatus == 7 ||
                                this.oneReport.trialStatus == 8 ||
                                this.oneReport.trialStatus == 9) &&
                            this.planList.length == 0
                        ) {
                            this.active = 2;
                            this.changeTab(2);
                            this.isShow = false;
                        } else {
                            this.isShow = true;
                            this.$refs.order.getData(
                                this.oneReport,
                                res.data.list
                            );
                        }
                    }
                });
            } else {
                if (
                    this.oneReport.trialStatus == 1 ||
                    this.oneReport.trialStatus == 7 ||
                    this.oneReport.trialStatus == 8 ||
                    this.oneReport.trialStatus == 9
                ) {
                    this.active = 2;
                    this.changeTab(2);
                    this.isShow = false;
                } else {
                    this.isShow = true;
                    this.$refs.order.getData(this.oneReport, []);
                }
            }
        },
        reportHistory() {
            let data = {
                param: {
                    nicheId: this.$route.query.id,
                    type: 2,
                },
                pageSize: this.$refs.history.$data.pagesize,
                pageNum: this.$refs.history.$data.currentPage,
            };
            reportHistory(data).then((res) => {
                if (res.code == 200) {
                    this.$refs.history.getData(res.data.list, res.data.total);
                }
            });
        },
        reportAuditHistory() {
            let data = {
                param: {
                    nicheId: this.$route.query.id,
                    type: 1,
                },
                pageSize: this.$refs.record.$data.pagesize,
                pageNum: this.$refs.record.$data.currentPage,
            };
            reportHistory(data).then((res) => {
                if (res.code == 200) {
                    this.$refs.record.getData(res.data.list, res.data.total);
                }
            });

            // let data = {
            //     param: {
            //         nicheId: this.$route.query.id,
            //         auditStatusList: [1, 2],
            //     },
            //     pageSize: this.$refs.record.$data.pagesize,
            //     pageNum: this.$refs.record.$data.currentPage,
            // };
            // reportAuditHistory(data).then((res) => {
            //     if (res.code == 200) {
            //         this.$refs.record.getData(res.data.list, res.data.total);
            //     }
            // });
        },
    },
};
</script>

<style lang="scss" scoped>
.detail_box {
    width: 100%;
    box-sizing: border-box;
    height: 100%;
    font-size: 14px;
    display: flex;
    flex-direction: column;
    > div {
        background-color: #fff;
        border-radius: 4px;
        margin-bottom: 12px;
    }
    .other_box {
        flex: 1;
        display: flex;
        flex-direction: column;
        .tab_box {
            height: 52px;
            line-height: 52px;
            border-bottom: 1px solid #eeeeee;
            padding-left: 36px;

            span + span {
                margin-left: 48px;
            }
            span {
                position: relative;
                color: #333;
                font-size: 14px;
                font-weight: 600;
                display: inline-block;
                cursor: pointer;
            }
            .active {
                color: #2370eb;
            }
            .active:after {
                content: '';
                position: absolute;
                bottom: 0px;
                left: 50%;
                transform: translateX(-50%);
                width: 16px;
                height: 2px;
                background: #2370eb;
                border-radius: 1px 1px 0px 0px;
            }
        }
    }
    // .basic_box {
    // }
    // .report_box {
    //     background-color: #fff;
    //     border-radius: 4px;
    // }
}
</style>
