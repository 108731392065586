<template>
    <div class="basic">
        <h2>
            <!-- <i class="iconfont icon-icon_yingyongguanli"></i> -->
            <i class="el-icon-menu"></i>
            基础信息
            <el-button type="primary" size="mini" plain @click="onClose()">
                返回列表</el-button
            >
        </h2>
        <div class="info">
            <div>
                <p style="min-width: 300px">
                    <span>报备名称</span>
                    {{ data.reportName || '- -' }}
                </p>
                <p>
                    <span>商机ID</span>
                    {{ data.nicheNo || '- -' }}
                </p>
                <p>
                    <span>域名</span>
                    {{ returnDomain(data).domain }}
                    <i
                        class="iconfont icon-tanhao"
                        v-if="returnDomain(data).auditStatus == 2"
                    ></i>
                    <i class="iconfont icon-chenggong1" v-else></i>
                    <el-popover
                        placement="bottom"
                        width="260"
                        trigger="click"
                        v-if="
                            data.nicheDomainBOList &&
                            data.nicheDomainBOList.length > 1
                        "
                    >
                        <div class="domin_box">
                            <p
                                v-for="item in data.nicheDomainBOList"
                                :key="item.id"
                            >
                                <span>域名</span>
                                <em> {{ item.domain }} </em>
                                <i
                                    class="iconfont icon-tanhao"
                                    v-if="item.auditStatus == 2"
                                ></i>
                                <i class="iconfont icon-chenggong1" v-else></i>
                            </p>
                        </div>
                        <el-button slot="reference" class="reference"
                            >更多</el-button
                        >
                    </el-popover>
                </p>
            </div>
            <div>
                <p>
                    <span>所在地</span>
                    {{
                        data.areaType == 1
                            ? $address.address(data.provinceId, data.cityId)
                            : data.abroadArea || '- -'
                    }}
                </p>
                <p style="min-width: 300px">
                    <span>创建时间</span>
                    {{ data.createTime ? data.createTime.slice(0, 10) : '- -' }}
                </p>
                <p>
                    <span>报备品牌</span>
                    阿里企业邮箱
                </p>
            </div>
            <div>
                <p>
                    <span>部门</span>
                    <el-tooltip placement="top">
                        <div slot="content">
                            <p
                                style="line-height: 2"
                                v-for="item in data.departmentAdminDOList"
                                :key="item.id"
                            >
                                {{ item.departmentName }}
                            </p>
                        </div>
                        <em style="font-style: normal">
                            {{ returnName(data) }}
                        </em>
                    </el-tooltip>
                    <!-- {{ data.departmentName || '- -' }} -->
                </p>
                <p>
                    <span>人员</span>
                    {{ data.adminName || '- -' }}
                </p>
                <p>
                    <span>合作人</span>
                    <el-tooltip
                        v-if="
                            data.nicheCollaboratorBOList &&
                            data.nicheCollaboratorBOList.length > 0
                        "
                        class="item"
                        effect="dark"
                        placement="top"
                    >
                        <div slot="content">
                            <p
                                style="line-height: 1.8"
                                v-for="item in data.nicheCollaboratorBOList"
                                :key="item.id"
                            >
                                {{ item.collaboratorAdminName }}
                            </p>
                        </div>
                        <span>{{
                            data.nicheCollaboratorBOList[0]
                                .collaboratorAdminName
                        }}</span>
                    </el-tooltip>
                    <span
                        v-if="
                            data.nicheCollaboratorBOList &&
                            data.nicheCollaboratorBOList.length > 0
                        "
                        class="circle_toast"
                    >
                        {{ data.nicheCollaboratorBOList.length }}
                    </span>
                    <span v-else>- -</span>
                    <el-button
                        v-if="collaborator && data.reportStatus == 3"
                        type="primary"
                        size="mini"
                        plain
                        @click="onUpdate()"
                    >
                        编辑</el-button
                    >
                </p>
            </div>
        </div>
        <!-- 合作人调整 -->
        <el-dialog
            :visible.sync="dialogCooperation"
            width="560px"
            :before-close="handleClose"
            append-to-body
            class="clue_assist"
        >
            <template slot="title">
                <div
                    style="
                        display: flex;
                        align-items: center;
                        color: #000000;
                        font-size: 16px;
                        font-weight: 500;
                    "
                >
                    合作人调整
                </div>
            </template>
            <div>
                <AddCooperation ref="addcooperation" @close="handleClose" />
            </div>
        </el-dialog>
    </div>
</template>

<script>
import AddCooperation from './addCooperation.vue';
export default {
    name: '',
    props: {},
    data() {
        return {
            data: {},
            collaborator: false,
            dialogCooperation: false,
        };
    },
    components: {
        AddCooperation,
    },
    created() {},
    methods: {
        getData(data) {
            var btnArr = JSON.parse(sessionStorage.getItem('isButArr'));
            let btn = {};
            for (let i in btnArr) {
                btn['' + btnArr[i].route] = true;
            }

            this.collaborator = btn.collaborator;
            console.log(data);
            this.data = data;
        },
        returnDomain(data) {
            if (data.nicheDomainBOList && data.nicheDomainBOList.length > 0) {
                let index = data.nicheDomainBOList.findIndex((item) => {
                    return (
                        item.auditStatus == 1 && data.trialDomain == item.domain
                    );
                });
                if (index != -1) {
                    return data.nicheDomainBOList[index];
                } else {
                    return data.nicheDomainBOList[0];
                }
            } else {
                return '- -';
            }
        },
        onClose() {
            this.$router.back(-1);
        },
        returnName(data) {
            var str = '';
            data.departmentAdminDOList
                ? data.departmentAdminDOList.forEach((item) => {
                      if (item.main == 1) {
                          str = item.departmentName;
                      }
                  })
                : '';
            return str;
        },
        onUpdate() {
            this.dialogCooperation = true;
            setTimeout(() => {
                this.$refs.addcooperation.getData(this.data);
            }, 0);
        },
        handleClose() {
            this.dialogCooperation = false;
            this.$emit('reportGet');
        },
    },
};
</script>

<style lang="scss" scoped>
.basic {
    h2 {
        padding-left: 16px;
        padding-top: 20px;
        padding-bottom: 16px;
        font-size: 14px;
        font-weight: 600;
        i {
            font-weight: normal;
            color: #f7b84f;
        }
        button {
            background: #fff;
            color: #2370eb;
            float: right;
            margin-right: 12px;
        }
        .el-button--primary.is-plain:focus,
        .el-button--primary.is-plain:hover {
            background: #fff;
            color: #2370eb;
        }
    }
    .info {
        padding-left: 31px;
        padding-bottom: 12px;
        > div {
            display: flex;
            flex-wrap: nowrap;

            p {
                flex: 1;
                font-size: 14px;
                color: #333;
                font-weight: 600;
                span {
                    color: #666666;
                    font-weight: normal;
                    display: inline-block;
                    width: 70px;
                }
                span:nth-child(2) {
                    width: auto;
                }
            }
        }
        > div + div {
            margin-top: 16px;
        }
        button {
            background: #fff;
            color: #333;
            border-color: #feb516;
            border-radius: 30px;
            margin-left: 15px;
            padding: 6px 15px;
        }
    }
    .reference {
        border-radius: 2px !important;
        border: 1px solid #2370eb !important;
        width: 40px !important;
        height: 20px !important;
        font-size: 12px !important;
        color: #2370eb !important;
        padding: 0 !important;
        margin-left: 10px !important;
    }
    .icon-tanhao {
        vertical-align: 0px;
        color: #d0021b;
    }

    .icon-chenggong1 {
        vertical-align: middle;
        color: #2370eb;
        font-size: 14px;
    }
}
.circle_toast {
    background-color: #2370eb;
    color: #fff;
    display: inline-block;
    width: auto !important;
    color: #fff !important;
    padding: 3px 5px;
    line-height: 1;
    border: 1px solid #2370eb;
    border-radius: 20px;
    margin-left: 15px;
}
.domin_box {
    p {
        display: flex;
        align-items: center;
        span {
            display: block;
            width: 40px;
            margin-left: 15px;
            color: #666;
        }
        em {
            flex: 1;
            color: #333;
            font-style: normal;
            font-weight: 600;
        }
        .icon-tanhao {
            vertical-align: middle;
            color: #d0021b;
            margin-right: 15px;
        }

        .icon-chenggong1 {
            margin-top: 5px;
            color: #2370eb;
            font-size: 14px;
            margin-right: 15px;
        }
    }
}
</style>
