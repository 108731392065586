var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"basic"},[_c('h2',[_c('i',{staticClass:"el-icon-menu"}),_vm._v(" 基础信息 "),_c('el-button',{attrs:{"type":"primary","size":"mini","plain":""},on:{"click":function($event){return _vm.onClose()}}},[_vm._v(" 返回列表")])],1),_c('div',{staticClass:"info"},[_c('div',[_c('p',{staticStyle:{"min-width":"300px"}},[_c('span',[_vm._v("报备名称")]),_vm._v(" "+_vm._s(_vm.data.reportName || '- -')+" ")]),_c('p',[_c('span',[_vm._v("商机ID")]),_vm._v(" "+_vm._s(_vm.data.nicheNo || '- -')+" ")]),_c('p',[_c('span',[_vm._v("域名")]),_vm._v(" "+_vm._s(_vm.returnDomain(_vm.data).domain)+" "),(_vm.returnDomain(_vm.data).auditStatus == 2)?_c('i',{staticClass:"iconfont icon-tanhao"}):_c('i',{staticClass:"iconfont icon-chenggong1"}),(
                        _vm.data.nicheDomainBOList &&
                        _vm.data.nicheDomainBOList.length > 1
                    )?_c('el-popover',{attrs:{"placement":"bottom","width":"260","trigger":"click"}},[_c('div',{staticClass:"domin_box"},_vm._l((_vm.data.nicheDomainBOList),function(item){return _c('p',{key:item.id},[_c('span',[_vm._v("域名")]),_c('em',[_vm._v(" "+_vm._s(item.domain)+" ")]),(item.auditStatus == 2)?_c('i',{staticClass:"iconfont icon-tanhao"}):_c('i',{staticClass:"iconfont icon-chenggong1"})])}),0),_c('el-button',{staticClass:"reference",attrs:{"slot":"reference"},slot:"reference"},[_vm._v("更多")])],1):_vm._e()],1)]),_c('div',[_c('p',[_c('span',[_vm._v("所在地")]),_vm._v(" "+_vm._s(_vm.data.areaType == 1 ? _vm.$address.address(_vm.data.provinceId, _vm.data.cityId) : _vm.data.abroadArea || '- -')+" ")]),_c('p',{staticStyle:{"min-width":"300px"}},[_c('span',[_vm._v("创建时间")]),_vm._v(" "+_vm._s(_vm.data.createTime ? _vm.data.createTime.slice(0, 10) : '- -')+" ")]),_vm._m(0)]),_c('div',[_c('p',[_c('span',[_vm._v("部门")]),_c('el-tooltip',{attrs:{"placement":"top"}},[_c('div',{attrs:{"slot":"content"},slot:"content"},_vm._l((_vm.data.departmentAdminDOList),function(item){return _c('p',{key:item.id,staticStyle:{"line-height":"2"}},[_vm._v(" "+_vm._s(item.departmentName)+" ")])}),0),_c('em',{staticStyle:{"font-style":"normal"}},[_vm._v(" "+_vm._s(_vm.returnName(_vm.data))+" ")])])],1),_c('p',[_c('span',[_vm._v("人员")]),_vm._v(" "+_vm._s(_vm.data.adminName || '- -')+" ")]),_c('p',[_c('span',[_vm._v("合作人")]),(
                        _vm.data.nicheCollaboratorBOList &&
                        _vm.data.nicheCollaboratorBOList.length > 0
                    )?_c('el-tooltip',{staticClass:"item",attrs:{"effect":"dark","placement":"top"}},[_c('div',{attrs:{"slot":"content"},slot:"content"},_vm._l((_vm.data.nicheCollaboratorBOList),function(item){return _c('p',{key:item.id,staticStyle:{"line-height":"1.8"}},[_vm._v(" "+_vm._s(item.collaboratorAdminName)+" ")])}),0),_c('span',[_vm._v(_vm._s(_vm.data.nicheCollaboratorBOList[0] .collaboratorAdminName))])]):_vm._e(),(
                        _vm.data.nicheCollaboratorBOList &&
                        _vm.data.nicheCollaboratorBOList.length > 0
                    )?_c('span',{staticClass:"circle_toast"},[_vm._v(" "+_vm._s(_vm.data.nicheCollaboratorBOList.length)+" ")]):_c('span',[_vm._v("- -")]),(_vm.collaborator && _vm.data.reportStatus == 3)?_c('el-button',{attrs:{"type":"primary","size":"mini","plain":""},on:{"click":function($event){return _vm.onUpdate()}}},[_vm._v(" 编辑")]):_vm._e()],1)])]),_c('el-dialog',{staticClass:"clue_assist",attrs:{"visible":_vm.dialogCooperation,"width":"560px","before-close":_vm.handleClose,"append-to-body":""},on:{"update:visible":function($event){_vm.dialogCooperation=$event}}},[_c('template',{slot:"title"},[_c('div',{staticStyle:{"display":"flex","align-items":"center","color":"#000000","font-size":"16px","font-weight":"500"}},[_vm._v(" 合作人调整 ")])]),_c('div',[_c('AddCooperation',{ref:"addcooperation",on:{"close":_vm.handleClose}})],1)],2)],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('p',[_c('span',[_vm._v("报备品牌")]),_vm._v(" 阿里企业邮箱 ")])}]

export { render, staticRenderFns }