var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"basic"},[_vm._m(0),_c('div',{staticClass:"info"},[_c('div',[_c('p',{staticStyle:{"min-width":"300px"}},[_c('span',{staticStyle:{"width":"56px"}},[_vm._v("状态")]),_c('em',{staticClass:"status"},[_vm._v(" "+_vm._s(_vm.$MailStatus.reportStatus(_vm.data.reportStatus) + '-' + _vm.$MailStatus.trialStatus(_vm.data.trialStatus)))])]),_c('p',[_c('span',[_vm._v("报备时间")]),_vm._v(_vm._s(_vm.data.reportStartTime ? _vm.data.reportStartTime.slice(0, 10) : '- -' || '- -')+" ")]),_c('p',[_c('span',[_vm._v("回收时间")]),(_vm.data.reportStatus == 3 && _vm.reportInfoUpdate)?_c('el-date-picker',{staticStyle:{"width":"150px"},attrs:{"size":"mini","type":"date","value-format":"yyyy-MM-dd HH:mm:ss","clearable":false,"placeholder":"选择日期"},on:{"change":function (val) {
                            _vm.reportSetting('reportExpireTime', val);
                        }},model:{value:(_vm.data.reportExpireTime),callback:function ($$v) {_vm.$set(_vm.data, "reportExpireTime", $$v)},expression:"data.reportExpireTime"}}):_c('em',{staticStyle:{"font-style":"normal"}},[_vm._v(_vm._s(_vm.data.reportExpireTime ? _vm.data.reportExpireTime.slice(0, 10) : '- -'))])],1),_c('p',[_c('span',[_vm._v("报备库")]),(
                        _vm.reportInfoUpdate &&
                        _vm.data.reportStatus != 8 &&
                        _vm.data.reportStatus != 9
                    )?_c('el-select',{staticStyle:{"width":"200px"},attrs:{"size":"mini","placeholder":"请选择"},on:{"change":function (val) {
                            _vm.reportSetting('libraryCategory', val);
                        }},model:{value:(_vm.data.libraryCategory),callback:function ($$v) {_vm.$set(_vm.data, "libraryCategory", $$v)},expression:"data.libraryCategory"}},_vm._l((_vm.FilingList),function(item){return _c('el-option',{key:item.id,attrs:{"label":item.accountName,"disabled":_vm.returnDis(item.id),"value":item.id}},[_c('span',{staticStyle:{"float":"left"}},[_vm._v(_vm._s(item.accountName))]),_c('span',{staticStyle:{"float":"right","color":"#8492a6","font-size":"13px"}},[_vm._v(_vm._s(Number(item.maxReportNumber) - Number(item.consumedNumber) - Number(item.channelConsumedNumber)))])])}),1):_c('em',{staticStyle:{"font-style":"normal"}},[_vm._v(" "+_vm._s(_vm.data.libraryName || '- -')+" ")])],1)])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('h2',[_c('img',{attrs:{"src":require("@/img/report.png"),"alt":""}}),_vm._v(" 报备信息 ")])}]

export { render, staticRenderFns }