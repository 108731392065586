<template>
    <div class="" style="width: 100%; height: 100%">
        <el-table
            :data="tableData"
            height="330"
            :header-cell-style="{
                background: '#FAFAFA',
                color: '#333333',
                'font-size': '13px',
                'font-weight': '600',
            }"
        >
            <el-table-column width="25"> </el-table-column>
            <el-table-column prop="createTime" label="操作时间" width="160">
                <template slot-scope="scope">
                    {{ scope.row.createTime ? scope.row.createTime : '- -' }}
                </template>
            </el-table-column>
            <el-table-column prop="adminName" label="操作人员" width="80">
            </el-table-column>
            <el-table-column prop="content" label="操作内容">
                <template slot-scope="scope">
                    {{ scope.row.content || '- -' }}
                </template>
            </el-table-column>
        </el-table>
        <div class="page-box">
            <el-pagination
                @current-change="handleCurrentChange"
                @size-change="handleSizeChange"
                :current-page.sync="currentPage"
                :page-size="pagesize"
                :page-sizes="[10, 20, 50]"
                layout="total,sizes,prev, pager, next, jumper"
                :total="total"
            ></el-pagination>
        </div>
    </div>
</template>

<script>
export default {
    name: '',
    props: {},
    data() {
        return {
            tableData: [],
            currentPage: 1,
            pagesize: 10,
            total: 0,
        };
    },
    components: {},
    created() {},
    methods: {
        getData(data, total) {
            this.tableData = [];
            this.tableData = data;
            this.total = total;
        },
        handleCurrentChange(val) {
            this.currentPage = val;
            this.$emit('reportAuditHistory');
        },
        handleSizeChange(val) {
            this.pagesize = val;
            this.$emit('reportAuditHistory');
        },
    },
};
</script>

<style lang="scss" scoped>
.el-table {
    font-size: 13px !important;
}
.page-box {
    background: #fff;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
}
</style>
